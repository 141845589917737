import OpenTypeFeature from '@/components/cms/OpenTypeFeature';

import DynamicComponent from './DynamicComponent';

const OpenTypeFeatureWrapper = ({ blok }) => {
  const children = blok.children || [];
  return (
    <OpenTypeFeature {...blok}>
      {children.map((blok) => (
        <DynamicComponent blok={blok} key={blok._uid} />
      ))}
    </OpenTypeFeature>
  );
};
export default OpenTypeFeatureWrapper;
