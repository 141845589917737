import Head from 'next/head';
import React from 'react';

import RichText from '../RichText';
import styles from './OpenTypeFeature.module.scss';

interface IProps {
  Text: string;
  featureSettings: any;
  featureSettingsHover?: any;
  Font: string;
  HoverFont?: string;
  Label?: string;
  ApplyStyle?: boolean;
  featureSettingsManual: string;
  featureSettingsManualHover: string;
  _uid: string;
}

const OpenTypeFeature: React.FC<IProps> = ({
  Text,
  featureSettings = [],
  featureSettingsHover = [],
  Font,
  HoverFont,
  Label,
  ApplyStyle,
  featureSettingsManual,
  featureSettingsManualHover,
  _uid,
  children
}) => {
  function createStyles() {
    const defaultStyle = featureSettingsManual
      ? featureSettingsManual
      : `"${featureSettings.join("','")}"`;
    const hoverStyle = featureSettingsManualHover
      ? featureSettingsManualHover
      : featureSettingsHover.length > 0
      ? `"${featureSettingsHover.join("','")}"`
      : defaultStyle;

    return {
      __html: `
      #openType${_uid} {
        --opentype-font: ${Font};
        --opentype-hover-font: ${HoverFont};
        --opentype-feature-settings: ${defaultStyle};
        --opentype-feature-settings-hover: ${hoverStyle};
      }
    `
    };
  }

  const contentClassName = ApplyStyle
    ? styles.opentypeFeatureStyled__Inner
    : styles.opentypeFeature__Inner;

  const content = (
    <>
      <Head>
        <style dangerouslySetInnerHTML={createStyles()} />
      </Head>
      <div className={contentClassName}>
        <RichText content={Text} />
      </div>
      {Label && <h5 className="u-h6">{Label}</h5>}
      <div className={styles.opentypeFeature__children}>{children}</div>
    </>
  );

  return Label ? (
    <article id={`openType${_uid}`} className={styles.opentypeFeature}>
      {content}
    </article>
  ) : (
    <div id={`openType${_uid}`} className={styles.opentypeFeature}>
      {content}
    </div>
  );
};

export default OpenTypeFeature;
